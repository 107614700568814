.time-container {
  background-image: linear-gradient(to right, #cc0000 2%, #8d255d 70%);
  display: flex;
  color: #fff;
}
.agenda-container {
  background: linear-gradient(90deg, rgb(8, 5, 8) 26%, rgb(46, 24, 38) 82%, rgb(77, 42, 58) 100%);
}

.agenda-table {
  width: 100%;
  margin-top: 20px;
  color: #fff;
  padding: 0 6%;
  background: linear-gradient(90deg, rgb(8, 5, 8) 26%, rgb(46, 24, 38) 82%, rgb(77, 42, 58) 100%);
}
.agenda-table td {
  color: #fff;
  border-bottom: none;
  padding-left: 40px;
  /* border-right: 0.5px solid; */
}
