.speaker-container {
  color: #fff;
  margin-bottom: 40px;
  background: linear-gradient(186deg, #522c3d 0%, #0f0017 48%, #0a0807 100%);
}
.imagesStyles {
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 30px;
  font-weight: 100;
  background: transparent;
  border: 1px solid;
  padding: 10px 30px;
}
.activeImagesStyles {
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
  background: linear-gradient(180deg, #cc0000 0%, #8d255d);
  border-radius: 5px;
  margin-right: 30px;
  padding: 10px 30px;
  font-weight: 600;
}
.activeImagesStyles:focus-visible,
.imagesStyles:focus-visible {
  outline: 1px dotted #fff !important;
  outline-offset: 2px;
}

.year-tabs {
  padding-left: 120px;
}

.heading {
  /* padding-left: 120px; */
  text-align: left;
}
.heading h2 {
  font-weight: 600;
}
.heading h2:first-child {
  color: #999798;
  line-height: 20px;
}

.heading h2:last-child {
  margin-bottom: 40px;
  color: #fff;
}
