body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.multiplai-hero-banner p:nth-of-type(1) {
  margin-top: 30px;
}
.multiplai-hero-banner p:nth-of-type(2) {
  margin-top: -8px;
  margin-bottom: 50px;
}
.multiplai-hero-banner p:nth-of-type(3) a {
  color: #cc0000 !important;
  font-weight: bold;
}
.multiplai-hero-banner button {
  font-weight: 600 !important;
}
.currentspeakers h3 {
  color: #fff !important;
}
.currentspeakers button:focus-visible {
  outline: 2px dotted #fff !important;
  outline-offset: -3px;
}
.multiplai-hero-banner button {
  cursor: default;
}
